import { RefObject, useCallback, useState } from 'react';

import { useUserStatus } from '~/hooks/userStatus';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import {
  CommentAnswerType,
  QuestionV2,
  ReviewV2,
} from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { fetchJson } from '~/lib/fetch';
import { QuestionOrReviewSchema } from '~/types/question-review';

interface SubmitReplyHookProps {
  comment?: any;
  type: DEAL_COMMENT_TYPE;
  dealId: number;
  currentComment?: ReviewV2 | QuestionV2;
  mutateComment?: Function;
  mutateReviewStats?: Function;
  successCallback?: ((data: ReviewV2) => void) | ((data: QuestionV2) => void);
  formRef?: RefObject<HTMLFormElement>;
  answerType?: CommentAnswerType;
}

interface CommentPostType {
  comment: string;
  followup: boolean;
  title?: string;
  rating?: number;
  answer_type?: string;
  parent?: number;
}

export function useSubmitReply({
  type,
  comment,
  dealId,
  currentComment,
  mutateComment = () => {},
  mutateReviewStats = () => {},
  successCallback,
  formRef,
  answerType = null,
}: SubmitReplyHookProps) {
  const dispatchEvent = useEventDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateUserStatus } = useUserStatus(dealId);
  const [errors, setErrors] = useState({});

  const handleSubmit = useCallback(
    (values: QuestionOrReviewSchema) => {
      const subPath =
        type === DEAL_COMMENT_TYPE.QUESTION ? 'questions' : 'reviews';
      const urlComponents = [`/api/v2/deals/${dealId}/${subPath}`];

      const post: CommentPostType = {
        comment: values.comment,
        followup: !!values.notify,
      };

      if (answerType) {
        post.answer_type = answerType;
      }

      if (values.title) {
        post.title = values.title;
      }

      if (!comment) {
        if (type === DEAL_COMMENT_TYPE.REVIEW) {
          post.rating = values.rating;
        }
      }

      // edit or reply to comment
      if (comment || currentComment) {
        urlComponents.push((currentComment?.id || comment.id).toString());
        urlComponents.push(currentComment ? 'edit' : 'reply');

        // in order to edit a reply we need to send the parent id
        if (currentComment?.parent_id) {
          post.parent = currentComment.parent_id;
        }
      }

      const url = urlComponents.join('/') + '/';

      const urlEncodedPost = Object.entries(post)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

      setIsLoading(true);
      setErrors({});

      fetchJson(url, {
        method: currentComment ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedPost,
      })
        .then(async (data: ReviewV2) => {
          await Promise.all([
            mutateComment(),
            mutateReviewStats(),
            mutateUserStatus(),
          ]);

          if (successCallback) {
            successCallback(data);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          err.response
            ?.json()
            .then((data: { [key: string]: Array<string> | String }) => {
              setErrors(data);

              for (const [key, value] of Object.entries(data)) {
                formRef?.current?.setError(key, {
                  message:
                    typeof value === 'string'
                      ? value
                      : value?.[0] || 'Something went wrong',
                });
              }
            });

          setIsLoading(false);
        })
        .finally(() => dispatchEvent('pdp:toggleBuyButton', false));
    },
    [
      type,
      dealId,
      answerType,
      comment,
      currentComment,
      mutateComment,
      mutateReviewStats,
      mutateUserStatus,
      successCallback,
      formRef,
      dispatchEvent,
    ],
  );

  return { handleSubmit, isLoading, errors };
}
