import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import useUser from '~/lib/user';
import { getUtmParamsForSocialShare, openExternalWindow } from '~/lib/util';
import { gtag } from '~/lib/util/dataLayer';
import {
  FACEBOOK_SHARE_URL,
  LINKEDIN_SHARE_URL,
  TWITTER_SHARE_URL,
  POST_SHARE_LINK_URL,
} from '~/lib/util/constants';
import { SOCIAL_SHARE_COPY_NAME, SocialShareType } from '~/constants/global';
import { generateShortenedKey } from '~/lib/util/helpers';
import useCopyToClipboard from '~/lib/util/hooks/copyToClipboard';
import { ShareMethod, UseSocialShareProps } from '~/types/global';
import { Parameters } from '~/types/gtag';
import { fetchJson } from '~/lib/fetch';

type SocialLinkTypes = {
  [key: string]: string;
  email: string;
  facebook: string;
  linkedin: string;
  twitter: string;
};

type GAPropsType = {
  socialName: string;
};

function getTwitterText(title: string, subheader?: string) {
  if (!subheader) {
    return encodeURIComponent(
      `This lifetime deal on ${title} is so good I had to tell you about it.`,
    );
  }

  return encodeURIComponent(
    `${subheader}. No monthly payments—ever. Check it out here:`,
  );
}

export function getEncodedShareableLink(
  shortKey: string,
  shareType: SocialShareType,
) {
  return typeof window === 'undefined'
    ? ''
    : encodeURIComponent(
        `${window.location.origin}${POST_SHARE_LINK_URL}${shareType}${shortKey}/`,
      );
}

function getSocialNetworkURL({
  email,
  facebook,
  twitter,
  linkedin,
  shareableText,
  title,
  showShortLink,
  shortKey,
  subheader,
}: {
  email: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  shareableText: string;
  title: string;
  showShortLink?: boolean;
  shortKey?: string;
  subheader?: string;
}) {
  // Additional social network parameters (i.e: utm parameters) to append
  // to the shareableText value.
  const params = {
    email: [
      `mailto:?body=${
        showShortLink && shortKey
          ? getEncodedShareableLink(shortKey, SocialShareType.email)
          : encodeURIComponent(shareableText + email)
      }`,
      `subject=${encodeURIComponent(`Share ${title}`)}`,
    ],
    facebook: [
      `u=${
        showShortLink && shortKey
          ? getEncodedShareableLink(shortKey, SocialShareType.facebook)
          : encodeURIComponent(shareableText + facebook)
      }`,
      `quote=${encodeURIComponent(shareableText + facebook)}`,
    ],
    twitter: [
      `url=${
        showShortLink && shortKey
          ? getEncodedShareableLink(shortKey, SocialShareType.x)
          : encodeURIComponent(shareableText + twitter)
      }`,
      `text=${getTwitterText(title, subheader)}`,
    ],
    linkedin: [
      `url=${
        showShortLink && shortKey
          ? getEncodedShareableLink(shortKey, SocialShareType.linkedin)
          : encodeURIComponent(shareableText + linkedin)
      }`,
    ],
  };
  return {
    email: `${params.email.join('&')}`,
    facebook: `${FACEBOOK_SHARE_URL}${params.facebook.join('&')}`,
    twitter: `${TWITTER_SHARE_URL}${params.twitter.join('&')} `,
    linkedin: `${LINKEDIN_SHARE_URL}${params.linkedin.join('&')} `,
  };
}

export function useSocialShare({
  dealId,
  dealSlug,
  publicName,
  shareType,
  content,
  showShortLink,
}: UseSocialShareProps) {
  const { user } = useUser();
  const [, copyToClipboard] = useCopyToClipboard();
  const [processingShareLink, setProcessingShareLink] = useState(false);
  const [shareLinkError, setShareLinkError] = useState('');

  const pdpUrl = dealSlug ? `/products/${dealSlug}/` : '';

  const socialParams = useMemo(() => {
    return getUtmParamsForSocialShare({
      data: { slug: dealSlug, id: dealId },
      userId: user?.id,
      shareType: shareType,
      content,
    });
  }, [dealId, dealSlug, user?.id, shareType, content]);

  const origin = typeof window === 'undefined' ? '' : window.location.origin;
  const shareableLink = !origin ? '' : `${origin}${pdpUrl}`;
  const shareableLinkWithParams = `${shareableLink}${socialParams.text || ''}`;
  const shortKey = useMemo(
    () => generateShortenedKey(shareableLinkWithParams),
    [shareableLinkWithParams],
  );
  const issueWithShortKey = shareLinkError && !processingShareLink;
  const validShortKey = !!(showShortLink && shortKey && !issueWithShortKey);

  const copyShareLink = useMemo(() => {
    return validShortKey && origin
      ? `${origin}${POST_SHARE_LINK_URL}1${shortKey}/`
      : shareableLinkWithParams;
  }, [validShortKey, origin, shortKey, shareableLinkWithParams]);

  const socialLinks: SocialLinkTypes = useMemo(() => {
    return getSocialNetworkURL({
      email: socialParams.email,
      facebook: socialParams.facebook,
      twitter: socialParams.twitter,
      linkedin: socialParams.linkedin,
      shareableText: shareableLink,
      title: publicName,
      showShortLink: validShortKey,
      shortKey,
    });
  }, [
    socialParams.email,
    socialParams.facebook,
    socialParams.twitter,
    socialParams.linkedin,
    shareableLink,
    publicName,
    validShortKey,
    shortKey,
  ]);

  const sendSocialShareLink = async (socialName: string): Promise<boolean> => {
    let result = true;
    if (!validShortKey) {
      // if we don't have a valid short key, skip sending the share link
      return result;
    }

    const formData = new FormData();
    formData.append('link', shareableLinkWithParams);

    const socialType =
      SocialShareType[
        socialName.toLowerCase() as keyof typeof SocialShareType
      ] || 0;
    setProcessingShareLink(true);
    await fetchJson(`${POST_SHARE_LINK_URL}${socialType}${shortKey}/`, {
      method: 'POST',
      include: 'credentials',
      body: formData,
    })
      .catch((e) => {
        setShareLinkError('Failed to generate share link. Please try again.');
        console.error(e);
        result = false;
      })
      .finally(() => {
        setProcessingShareLink(false);
      });
    return result;
  };

  const openSocialWindow = async (socialName: string) => {
    const res = await sendSocialShareLink(socialName);

    if (!res) {
      return;
    }

    const windowName =
      socialName !== 'email'
        ? `share-modal-${Math.round(Math.random() * 10000)}`
        : '_self';
    openExternalWindow({
      url: socialLinks[socialName],
      windowName,
      popup: socialName !== 'email',
    });
  };

  const clipboardCopy = async () => {
    const res = await sendSocialShareLink('share_link');

    if (!res) {
      return;
    }

    const copied = await copyToClipboard(copyShareLink);
    if (copied) {
      toast.success('Link copied to clipboard!', { id: 'clipboard' });
    } else {
      toast.error('Failed to copy link to clipboard.', { id: 'clipboard' });
    }
  };

  const trackOnGA = ({ socialName }: GAPropsType) => {
    let shareMethod = (
      socialName === 'twitter' ? 'x' : socialName
    ) as ShareMethod;

    if (socialName === SOCIAL_SHARE_COPY_NAME) {
      shareMethod = 'share_link';
    }

    const data: Partial<Parameters> = {
      shareMethod,
      pageType: shareType,
      dealId,
    };

    gtag('event', 'product_share', data);
  };

  return {
    copyShareLink,
    shareLinkError,
    processingShareLink,
    openSocialWindow,
    clipboardCopy,
    trackOnGA,
  };
}
