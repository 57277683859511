import { Button, Form, Link } from '@appsumo/dorado-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { memo, useRef } from 'react';
import { useDiscussionStats } from '~/hooks/useDiscussionStats';
import {
  DEAL_COMMENT_TYPE,
  REPLY_MAX_LENGTH,
} from '~/lib/discussions/constants';
import { CommentAnswerType, CommentV2 } from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { useSubmitReply } from '~/lib/util/hooks/submitReply';
import { YupReplySchema } from '~/types/question-review';
import BoxMesssage from '../ui/BoxMesssage';

interface SubmitReplyProps {
  mainComment?: any;
  currentComment?: any;
  type: DEAL_COMMENT_TYPE;
  dealId: number;
  answerType: CommentAnswerType;
  mutateComment?: Function;
  onSuccess?: (comment: CommentV2) => void;
  onCancel?: () => void;
}

// TODO: update to allow for edits
export default memo(function SubmitReply({
  type,
  mainComment,
  currentComment,
  dealId,
  mutateComment,
  answerType,
  onSuccess,
  onCancel,
}: Readonly<SubmitReplyProps>): JSX.Element {
  const dispatchEvent = useEventDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { mutateDiscussionStats } = useDiscussionStats(
    mainComment.deal_id,
    type,
  );
  const { handleSubmit, errors, isLoading } = useSubmitReply({
    type,
    mutateComment,
    mutateReviewStats: mutateDiscussionStats,
    comment: mainComment,
    currentComment,
    dealId,
    formRef,
    answerType,
    successCallback: onSuccess,
  });

  return (
    <div className="w-full" data-testid="review-submit-reply">
      <Form
        resolver={yupResolver(YupReplySchema)}
        onSubmit={handleSubmit}
        defaultValues={{ comment: currentComment?.comment || '' }}
        ref={formRef}
      >
        <div className="space-y-4">
          <div
            onFocus={() => dispatchEvent('pdp:toggleBuyButton', true)}
            onBlur={() => dispatchEvent('pdp:toggleBuyButton', false)}
          >
            <Form.TextArea
              rows={5}
              name="comment"
              label={
                type === DEAL_COMMENT_TYPE.QUESTION
                  ? 'Your answer'
                  : 'Your reply'
              }
              maxLength={REPLY_MAX_LENGTH}
            />
          </div>

          {!!Object.keys(errors).length && (
            <BoxMesssage className="mb-4 bg-brick">
              <FontAwesomeIcon icon={faCheckCircle} />
              <p>
                Whoops! Something went wrong on our end. Please try submitting
                again.
              </p>
            </BoxMesssage>
          )}

          <Button type="submit" className="w-full" primary disabled={isLoading}>
            {type === DEAL_COMMENT_TYPE.QUESTION
              ? 'Submit answer'
              : 'Submit reply'}
          </Button>

          {onCancel && (
            <Button
              type="button"
              className="w-full"
              tertiary
              onClick={onCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
          )}

          <div className="text-sm">
            By submitting, I agree to&nbsp;
            <Link href="/community-guidelines/" styled>
              AppSumo&apos;s Community Guidelines.
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
});
