import { useModal } from '@appsumo/dorado-react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useDiscussionStats } from '~/hooks/useDiscussionStats';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { DiscussionOptionsProps } from '~/lib/discussions/types';
import { PDPContext } from '~/lib/product/context';
import {
  DISCUSSION_EDIT_EXPERIMENT_NAME,
  isIndexablePage,
} from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';
import { Deal } from '~/types/deal';

import { useExperiment } from '~/lib/experiment';
import DiscussionDeleteModal from './DiscussionDeleteModal';

function DiscussionOptions({
  deleteUrl,
  discussionType,
  deletedCallback = () => {},
  onEditCallback,
}: Readonly<DiscussionOptionsProps>) {
  const [showOptions, setShowOptions] = useState(false);
  const { deal }: { deal: Deal } = useContext(PDPContext);
  const { mutateDiscussionStats } = useDiscussionStats(
    deal.id,
    discussionType === CommentV2Type.Review
      ? DEAL_COMMENT_TYPE.REVIEW
      : DEAL_COMMENT_TYPE.QUESTION,
  );
  const { variant: editVariant } = useExperiment(
    DISCUSSION_EDIT_EXPERIMENT_NAME,
  );
  const { asPath, push } = useRouter();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const showEdit = editVariant === 'enabled' && !!onEditCallback;

  const [showModal, hideModal] = useModal(() => (
    <DiscussionDeleteModal
      title={`Delete ${discussionType}`}
      body={`Are you sure you want to delete your ${discussionType}? This can't be undone.`}
      deleteButtonText={`Delete ${discussionType}`}
      cancelCallback={hideModal}
      successCallback={onSuccessDelete}
      errorCallback={onErrorDelete}
      deleteUrl={deleteUrl}
    />
  ));

  const onSuccessDelete = useCallback(() => {
    toast.success(
      `${
        discussionType[0].toUpperCase() + discussionType.slice(1)
      } has been deleted!`,
    );

    hideModal();

    // redirect to aggregate page
    // the toast message will kept on the page since it's a client-side redirect
    if (
      isIndexablePage(asPath.split('?')[0]) &&
      discussionType !== CommentV2Type.Reply
    ) {
      push(`/products/${deal.slug}/${discussionType}s`);
    } else {
      deletedCallback();
      mutateDiscussionStats();
    }
  }, [
    discussionType,
    hideModal,
    asPath,
    push,
    deal.slug,
    deletedCallback,
    mutateDiscussionStats,
  ]);

  const onErrorDelete = useCallback(() => {
    toast.error('Something went wrong, please try again.');
    hideModal();
  }, [hideModal]);

  const onDelete = useCallback(
    (callback: Function) => {
      showModal();
      callback();
    },
    [showModal],
  );

  return (
    <div className="relative">
      <Menu
        as="div"
        className="relative"
        onClick={() => setShowOptions(!showOptions)}
      >
        {({ open, close }) => (
          <>
            <Menu.Button
              ref={buttonRef}
              className="text-slate hover:text-gray-500"
              data-testid="discussion-options"
            >
              <span className="sr-only">More options</span>
              <FontAwesomeIcon icon={faEllipsis} width="16" height="16" />
            </Menu.Button>
            <Transition
              show={open}
              unmount={false}
              enter="md:transition md:ease-out md:duration-100"
              enterFrom="md:transform md:opacity-0 md:scale-95"
              enterTo="md:transform md:opacity-100 md:scale-100"
              leave="md:transition md:ease-in md:duration-75"
              leaveFrom="md:transform md:opacity-100 md:scale-100"
              leaveTo="md:transform md:opacity-0 md:scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 space-y-2 rounded border border-gravel bg-white py-2 px-4 shadow"
              >
                <Menu.Item>
                  <button
                    type="button"
                    className="w-full text-left text-bolt hover:text-bolt-light-20"
                    onClick={() => onDelete(close)}
                  >
                    Delete
                  </button>
                </Menu.Item>
                {showEdit && (
                  <Menu.Item>
                    <button
                      data-testid="edit-button"
                      type="button"
                      className="w-full text-left text-bolt hover:text-bolt-light-20"
                      onClick={() => onEditCallback?.()}
                    >
                      Edit
                    </button>
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default memo(DiscussionOptions);
