import { memo, useCallback, useEffect, useMemo } from 'react';
import { Heading } from '@appsumo/dorado-react';
import Image from 'next/image';
import clsx from 'clsx';
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLink, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useSocialShare } from '~/hooks/share';
import { useConvertMetrics } from '~/lib/experiment';
import { SUMOTEST_SHARE_METRIC } from '~/constants/experiments';
import { SOCIAL_SHARE_COPY_NAME, ShareType } from '~/constants/global';
import {
  SocialActionsProps,
  SocialButtonConfig,
  SocialButtonProps,
} from '~/types/global';

const DEFAULT_SOCIAL_BUTTONS: SocialButtonConfig = {
  copy: {
    icon: faLink,
    className: 'text-slate',
  },
  email: {
    icon: faEnvelope,
    className: 'text-slate',
  },
  facebook: {
    icon: faFacebook as IconProp,
    className: 'text-[#4267B2]',
  },
  linkedin: {
    icon: faLinkedin as IconProp,
    className: 'text-[#0072B1]',
  },
  twitter: {
    icon: faXTwitter as IconProp,
    className: '',
  },
};

export const COLORFUL_SOCIAL_BUTTONS: SocialButtonConfig = {
  copy: {
    ...DEFAULT_SOCIAL_BUTTONS.copy,
    className: 'bg-dollar text-white',
  },
  email: {
    ...DEFAULT_SOCIAL_BUTTONS.email,
    className: 'bg-dorado text-white',
  },
  facebook: {
    ...DEFAULT_SOCIAL_BUTTONS.facebook,
    className: 'bg-[#316FF6] text-white',
  },
  twitter: {
    ...DEFAULT_SOCIAL_BUTTONS.twitter,
    className: 'bg-[#14171A] text-white',
  },
  linkedin: {
    icon: faLinkedinIn as IconProp,
    className: 'bg-[#0072B1] text-white',
  },
};

const SocialButtons = memo(function SocialButtons({
  socialButtons,
  showColorfulButtons,
  handleAction,
}: SocialButtonProps) {
  return (
    <>
      {Object.entries(socialButtons).map(([name, { icon, className }]) => {
        const iconClassName = showColorfulButtons ? 'h-5 w-5' : 'h-6 w-6';

        return (
          <button
            data-testid={`social-share-${name}`}
            key={name}
            className={clsx(
              'flex items-center p-2',
              showColorfulButtons
                ? 'rounded-full'
                : 'rounded border border-sundog',
              className,
            )}
            onClick={() => handleAction(name)}
          >
            <FontAwesomeIcon icon={icon} className={iconClassName} />
          </button>
        );
      })}
    </>
  );
});

const CopyText = memo(function CopyText({
  labelText = 'Or copy link',
  shareLink,
  handleAction,
}: {
  labelText?: string;
  shareLink: string;
  handleAction: (action: string) => void;
}) {
  return (
    <div className="relative flex w-full grow flex-col">
      <label
        htmlFor="shareable-link"
        className="mb-2 font-header font-semibold text-midnight"
      >
        {labelText}
      </label>
      <input
        type="text"
        name="shareable-link"
        className="rounded-[4px] border border-gravel bg-iceberg py-2 px-4 pr-8 text-midnight outline-none"
        readOnly
        value={shareLink}
      />
      <button
        className="absolute bottom-0 right-0 m-1 w-fit"
        onClick={() => handleAction('copy')}
      >
        <FontAwesomeIcon icon={faCopy} width="24" height="24" className="m-1" />
      </button>
    </div>
  );
});

export const SocialShareActions = memo(function SocialShareActions({
  dealId,
  dealSlug,
  publicName,
  shareType,
  content,
  socialButtons,
  showColorfulButtons,
  showCopyText,
  copyTextLocation,
}: SocialActionsProps) {
  const {
    openSocialWindow,
    clipboardCopy,
    trackOnGA,
    copyShareLink,
    shareLinkError,
  } = useSocialShare({
    dealId,
    dealSlug,
    publicName,
    shareType,
    content,
    showShortLink: showColorfulButtons,
  });
  const { convert } = useConvertMetrics();

  const handleAction = useCallback(
    (socialName: string) => {
      convert({ [SUMOTEST_SHARE_METRIC]: 1 });
      if (socialName === SOCIAL_SHARE_COPY_NAME) {
        clipboardCopy();
      } else {
        openSocialWindow(socialName);
      }

      trackOnGA({ socialName });
    },
    [clipboardCopy, convert, openSocialWindow, trackOnGA],
  );

  useEffect(() => {
    shareLinkError && toast.error(shareLinkError);
  }, [shareLinkError]);

  if (showCopyText) {
    delete socialButtons.copy;
  }

  return (
    <div className="flex flex-col gap-4">
      {showCopyText && (
        <div
          className={`flex ${
            copyTextLocation === 'TOP' ? 'order-1' : 'order-3'
          }`}
        >
          <CopyText
            labelText="Or copy link"
            shareLink={copyShareLink}
            handleAction={handleAction}
          />
        </div>
      )}
      <div className="order-2 flex gap-4">
        <SocialButtons
          socialButtons={socialButtons}
          showColorfulButtons={showColorfulButtons}
          handleAction={handleAction}
        />
      </div>
    </div>
  );
});

const MemoizedSocialShare = memo(function SocialShare({
  dealId,
  publicName,
  dealSlug,
  mediaUrl,
  shareType = ShareType.PDP,
  content,
  useColorfulButtons,
}: {
  dealId: number;
  publicName: string;
  dealSlug: string;
  mediaUrl?: string;
  shareType?: ShareType;
  content?: string;
  useColorfulButtons?: boolean;
}) {
  const socialButtons = useColorfulButtons
    ? COLORFUL_SOCIAL_BUTTONS
    : DEFAULT_SOCIAL_BUTTONS;

  const isRedemptionPage = useMemo(
    () => shareType === ShareType.REDEMPTION,
    [shareType],
  );

  const headerText = useMemo(
    () =>
      isRedemptionPage
        ? `Support ${publicName} with a share`
        : 'Like the deal you just scored?',
    [isRedemptionPage, publicName],
  );

  const bodyText = useMemo(
    () =>
      isRedemptionPage
        ? `Give a boost to the ${publicName} team by shouting them out in your networks. Your share will help them connect with more entrepreneurs like you.`
        : 'Share on social and let your friends know where the good stuff is.',
    [isRedemptionPage, publicName],
  );

  return (
    <div
      className="flex h-fit w-full flex-col gap-4 rounded-lg border border-sundog bg-white p-6 shadow-md md:w-fit"
      data-testid="checkout-social-share"
    >
      <div className="flex gap-4">
        {!!mediaUrl && (
          <div>
            <Image
              src={`${mediaUrl}?width=48&height=30&aspect_ratio=3:2`}
              alt="deal-thumbnail"
              width={48}
              height={32}
              className="mt-2 rounded"
            />
          </div>
        )}
        <div>
          <Heading.H5>{headerText}</Heading.H5>
          <p className="text-sm text-grace">{bodyText}</p>
        </div>
      </div>

      <SocialShareActions
        dealId={dealId}
        dealSlug={dealSlug}
        publicName={publicName}
        shareType={shareType}
        content={content}
        socialButtons={socialButtons}
        showColorfulButtons={!!useColorfulButtons}
      />
    </div>
  );
});

export { MemoizedSocialShare as SocialShare };
