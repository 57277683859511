import clsx from 'clsx';
import { memo } from 'react';

function BoxMessage({
  className,
  children,
}: Readonly<{
  className?: string;
  children: React.ReactNode;
}>) {
  return (
    <div
      className={clsx(
        'flex items-center gap-4 rounded-sm p-4 text-sm text-white',
        className,
      )}
    >
      {children}
    </div>
  );
}

export default memo(BoxMessage);
