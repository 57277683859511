import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useCommentCard from '~/hooks/discussion/useCommentCard';
import {
  DEAL_COMMENT_TYPE,
  DISCUSSION,
  QUESTION_EVENT_TYPE_REPLY,
  QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME,
} from '~/lib/discussions/constants';
import {
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionStatus,
  IQuestionThreadProps,
  QuestionV2,
} from '~/lib/discussions/types';
import { mapToQuestionComment } from '~/lib/discussions/utils';
import { useEvent } from '~/lib/events';
import { PDPContext } from '~/lib/product/context';
import { LOGIN_URL, isIndexablePage } from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';
import { UserObjectProps } from '~/types/user';
import HelpfulButton from '../HelpfulButton';
import SubmitReply from '../SubmitReply';
import CreatorReply from './CreatorReply';
import DiscussionActions from './DiscussionActions';
import DiscussionOptions from './DiscussionOptions';
import DiscussionQuestionInfo from './DiscussionQuestionInfo';
import DiscussionTag from './DiscussionTag';
import QuestionThread from './QuestionThread';

function QuestionCard({
  question,
  showCollapseIcon = false,
  expandCard = false,
  onDelete = () => {},
}: Readonly<{
  question: QuestionV2;
  showCollapseIcon?: boolean;
  expandCard?: boolean;
  onDelete?: (id: number) => void;
}>): JSX.Element {
  const { push, asPath } = useRouter();
  // set thread to be open by default on indexable pages
  const [toggleThread, setToggleThread] = useState(
    isIndexablePage(asPath.split('?')[0]),
  );

  const {
    deal: { partner_user_ids = [], slug },
    userStatus,
    user,
  } = useContext(PDPContext);

  const {
    comment,
    isPartner,
    isOwner,
    stateHelpfulCount,
    stateHasUserUpvoted,
    toggleVote,
    disableVoteBtn,
    creatorReply,
    showDiscussionTag,
    isCollapsed,
    setCollapseValue,
    showStatusModerator,
    showSubmitReply,
    appendChild,
    replaceChild,
    setStatus,
    removePartnerStaffReply,
    answerType,
    creatorReplyEditEnabled,
    setCreatorReplyEditEnabled,
  } = useCommentCard(
    question,
    CommentV2Type.Question,
    partner_user_ids,
    expandCard,
    showCollapseIcon,
  );

  const questionThread: IQuestionThreadProps = useMemo(() => {
    let threads = (comment?.children ?? []).filter(
      (child: QuestionV2) =>
        child.id !== creatorReply?.id && !child.answer_type,
    );

    // With the new answer type feature, we need to extract replies from the creator's reply.
    // Now, a creator's reply is a direct answer and should not have further replies unlike in the old UI.
    // Therefore, we add any previous replies within the creator's answer
    // to the main thread as first-level children.
    const creatorChildren: QuestionV2[] | undefined = comment.children?.find(
      (x) => x.id === creatorReply?.id,
    )?.children;

    if (creatorChildren?.length) {
      threads = threads
        .concat(creatorChildren)
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime(),
        );
    }

    return {
      questionId: comment.id,
      questionUrl: comment.display_path,
      comments: threads.map((child) =>
        mapToQuestionComment(
          child,
          comment.id,
          user as UserObjectProps,
          partner_user_ids,
          child.parent_id === question.id &&
            !question.children?.some((c) => c.id === child.id), // is new if isn't in the original comments
        ),
      ),
    } as IQuestionThreadProps;
  }, [
    comment?.children,
    comment.id,
    comment.display_path,
    creatorReply?.id,
    user,
    partner_user_ids,
    question.children,
    question.id,
  ]);

  // Sum all children levels
  const [questionThreadCount, setQuestionThreadCount] = useState(
    questionThread.comments?.reduce(
      (acc, comment) => acc + (comment?.comments?.length ?? 0),
      questionThread.comments?.length ?? 0,
    ) ?? 0,
  );

  const isModerated = useMemo(
    () => comment.status?.toLowerCase() !== DISCUSSION_STATUS_UNMODERATED,
    [comment.status],
  );

  const onCommentClick = useCallback(() => {
    if (!user?.is_authenticated && questionThreadCount <= 0) {
      window.location.href = `${LOGIN_URL}?next=${question.display_path}`;
      return;
    }

    setToggleThread(!toggleThread);
  }, [
    question.display_path,
    questionThreadCount,
    toggleThread,
    user?.is_authenticated,
  ]);

  // enable threads for moderators when expanding the card
  useEffect(() => {
    if (!isCollapsed && showCollapseIcon) {
      setToggleThread(isModerated);
    }
  }, [isCollapsed, showCollapseIcon, isModerated]);

  useEvent(QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME, ({ type, questionId }) => {
    if (comment.id !== questionId) {
      return;
    }

    const newCount =
      type === QUESTION_EVENT_TYPE_REPLY
        ? questionThreadCount + 1
        : questionThreadCount - 1;
    setQuestionThreadCount(newCount);
  });

  return (
    <div
      data-testid="questions-card-wrapper"
      className="relative flex flex-col items-stretch space-y-4 self-stretch rounded border border-gravel p-4 shadow-pdp"
    >
      <div className="absolute top-4 right-4 flex gap-3">
        {/* Only owner can see options */}
        {isOwner && (
          <DiscussionOptions
            deleteUrl={`/api/v2/deals/${comment.deal_id}/questions/${comment.id}/`}
            discussionType={CommentV2Type.Question}
            deletedCallback={() => onDelete(question.id)}
            onEditCallback={() =>
              push(`/products/${slug}/questions/${question.id}/edit/`)
            }
          />
        )}

        {showCollapseIcon && (
          <button
            title="Collapse review"
            onClick={setCollapseValue}
            className="px-0.5"
            data-testid="question-collapse-icon"
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${!isCollapsed && 'rotate-180'}`}
            />
          </button>
        )}
      </div>

      {showDiscussionTag && (
        <DiscussionTag
          status={comment.status?.toLowerCase() as DiscussionStatus}
          type={DISCUSSION.QUESTIONS}
          isOwner={isOwner}
        />
      )}

      <DiscussionQuestionInfo
        title={comment.title}
        comment={comment.comment}
        commentClass={
          isOwner || (!showDiscussionTag && showCollapseIcon) ? 'pr-14' : ''
        }
        userName={comment.user.username}
        publishDate={new Date(comment.created)}
        detailUrl={comment.display_path}
        hasPlus={comment.user.has_plus}
        isEdited={comment.edited}
      />

      {/* Collapsible content */}
      {(!isCollapsed || !showCollapseIcon) && (
        <>
          {!!creatorReply && !creatorReplyEditEnabled && (
            <CreatorReply
              {...creatorReply}
              className="first-letter:font-bold"
              onDelete={removePartnerStaffReply}
              onEdit={() => setCreatorReplyEditEnabled(true)}
            />
          )}

          <div className="flex w-full items-center justify-between">
            {isModerated ? (
              <button
                data-testid="question-comments-button"
                className="text-sm font-medium text-bolt hover:text-bolt-light-20"
                onClick={onCommentClick}
                type="button"
              >
                <FontAwesomeIcon icon={faComment} className="mr-1" />
                Comment
                {!!questionThreadCount && (
                  <span>&nbsp;({questionThreadCount})</span>
                )}
              </button>
            ) : (
              // empty div to keep the layout consistent
              <div></div>
            )}

            <HelpfulButton
              helpfulCount={stateHelpfulCount}
              hasUserUpvoted={stateHasUserUpvoted}
              isDisabled={disableVoteBtn}
              onToggleVote={toggleVote}
            />
          </div>
        </>
      )}

      {/* Partners are able to only Approve */}
      {showStatusModerator && (
        <DiscussionActions
          readOnly={
            !userStatus?.staff_moderator &&
            (!isPartner || (isPartner && isModerated))
          }
          canUndo={!!userStatus?.staff_moderator}
          canDecline={!!userStatus?.staff_moderator}
          status={comment.status?.toLowerCase() as DiscussionStatus}
          comment={comment}
          onChangeStatus={setStatus}
          type={DISCUSSION.QUESTIONS}
        />
      )}

      {/*
       * Using `block` and `hidden` classes to prevent internal state issues.
       * This is because delete and reply actions are handled internally on threads
       * and the `comment` state is not synced.
       *
       * Using conditional rendering will cause to re-render threads with the
       * outdated `comment` state.
       *
       * Remove margin top if there is no submit reply and no thread
       * (there is an extra space added by parent with space-y)
       */}
      <div
        className={clsx(
          !isCollapsed || !showCollapseIcon ? 'block' : 'hidden',
          { '!mt-0': !showSubmitReply && !toggleThread },
        )}
      >
        {showSubmitReply && (
          <SubmitReply
            dealId={comment.deal_id}
            mainComment={comment}
            currentComment={creatorReplyEditEnabled ? creatorReply : undefined}
            type={DEAL_COMMENT_TYPE.QUESTION}
            answerType={answerType}
            onSuccess={creatorReplyEditEnabled ? replaceChild : appendChild}
            onCancel={
              creatorReplyEditEnabled
                ? () => setCreatorReplyEditEnabled(false)
                : undefined
            }
          />
        )}

        <div
          data-testid="question-thread-container"
          className={clsx(
            'w-full border-t border-sundog',
            toggleThread ? 'block' : 'hidden',
            { 'mt-4': showSubmitReply },
          )}
        >
          <QuestionThread {...questionThread} onReply={appendChild} />
        </div>
      </div>
    </div>
  );
}

export default memo(QuestionCard);
