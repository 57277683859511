import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useContext, useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import ToggleText from '~/components/ui/ToggleText';
import { QUESTION_HEADER_MAX_LENGTH_SCALAR } from '~/constants/discussions';
import { DealCommentsContext } from '~/contexts/deal-comments';
import { DISCUSSION_TOGGLE_TEXT_MAX_LENGTH } from '~/lib/discussions/constants';
import { getHeaderBodyFromComment } from '~/lib/discussions/utils';
import { formatToHumanReadableDate, highlightText } from '~/lib/util';
import { isAggregatePage, isIndexablePage } from '~/lib/util/constants';

export interface IDiscussionQuestionInfo {
  title: string;
  comment: string;
  userName: string;
  publishDate: Date | number;
  detailUrl: string;
  commentClass?: string;
  hasPlus?: boolean;
  isEdited?: boolean;
}

function DiscussionQuestionInfo({
  title,
  comment,
  userName,
  publishDate,
  detailUrl,
  commentClass,
  hasPlus,
  isEdited,
}: Readonly<IDiscussionQuestionInfo>): JSX.Element {
  const { asPath } = useRouter();
  const { query } = useContext(DealCommentsContext);

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [header, body, maxLength]: [string, string, number] = useMemo(() => {
    let headerText = title;
    let bodyText = comment;

    // without title we need to get header and body from the same comment
    if (!title) {
      [headerText, bodyText] = getHeaderBodyFromComment(comment, {
        ellipsis: true,
      });
    }

    // If text is a little longer than the limit or is an indexable page,
    // no need to show "read more" button for a few extra words
    const readMoreLimit =
      bodyText.length <
        DISCUSSION_TOGGLE_TEXT_MAX_LENGTH * QUESTION_HEADER_MAX_LENGTH_SCALAR ||
      isAggregatePage(asPath.split('?')[0])
        ? bodyText.length + 1
        : DISCUSSION_TOGGLE_TEXT_MAX_LENGTH;

    return [
      query ? highlightText(headerText, query) : headerText,
      bodyText,
      readMoreLimit,
    ];
  }, [asPath, comment, query, title]);

  return (
    <div data-testid="discussion-question-info" className="space-y-1">
      <p
        data-testid="discussion-question-title"
        className={clsx(
          'font-header text-xl font-bold leading-6 text-black-pearl',
          commentClass ?? '',
        )}
      >
        Q:{' '}
        <span
          className="break-words"
          dangerouslySetInnerHTML={{ __html: header }}
        />
      </p>

      {!!body.length && (
        <ToggleText
          initExpanded={isDesktop || isIndexablePage(asPath.split('?')[0])}
          highlight={query}
          className="text-midnight"
          text={body}
          maxLength={
            isDesktop || isIndexablePage(asPath.split('?')[0])
              ? body.length + 1
              : maxLength
          }
        />
      )}

      <div className="flex flex-wrap items-center justify-between text-sm text-grace">
        <div className="flex items-center gap-2 truncate">
          <span className="truncate font-semibold">{userName}</span>
          {hasPlus && (
            <span
              data-testid="plus-badge"
              className="rounded border-[0.5px] border-ready-red p-1 text-xs leading-none tracking-wider text-ready-red"
            >
              PLUS
            </span>
          )}
          <span
            className="h-1.5 w-1.5 shrink-0 rounded-full bg-gravel"
            aria-hidden="true"
          />
          <span className="shrink-0">
            {isEdited ? 'Edited ' : ''}
            {formatToHumanReadableDate(
              new Date(publishDate).toISOString(),
              {
                month: 'short',
              },
              'en-US',
            )}
          </span>
        </div>
        {!isIndexablePage(asPath.split('?')[0]) && (
          <Link
            className="text-slate hover:text-gray-500"
            href={detailUrl}
            target="_blank"
          >
            <span className="sr-only">See detail</span>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Link>
        )}
      </div>
    </div>
  );
}

export default memo(DiscussionQuestionInfo);
