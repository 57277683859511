import {
  faChevronDown,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';
import HelpfulButton from '~/components/discussions/HelpfulButton';
import CreatorReply from '~/components/discussions/card/CreatorReply';
import ReviewInfo, {
  IDiscussionReviewInfo,
} from '~/components/discussions/card/DiscussionReviewInfo';
import DiscussionUserInfo from '~/components/discussions/card/DiscussionUserInfo';
import useCommentCard from '~/hooks/discussion/useCommentCard';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import {
  DiscussionStatus,
  IDiscussionUserInfoProps,
  ReviewV2,
} from '~/lib/discussions/types';
import { PDPContext } from '~/lib/product/context';
import { isIndexablePage } from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';
import SubmitReply from '../SubmitReply';
import DiscussionActions from './DiscussionActions';
import DiscussionOptions from './DiscussionOptions';
import DiscussionTag from './DiscussionTag';

export enum HelpfulClickEventTypes {
  UPVOTE = 'upvote',
  DOWNVOTE = 'downvote',
}

const MemoizedReviewCard = React.memo(function ReviewCard({
  review,
  showCollapseIcon = false,
  expandCard = false,
  onDelete = () => {},
}: Readonly<{
  review: ReviewV2;
  showCollapseIcon?: boolean;
  expandCard?: boolean;
  onDelete?: (id: number) => void;
}>): JSX.Element {
  const {
    deal: { partner_user_ids = [], slug },
    userStatus,
  } = useContext(PDPContext);

  const {
    comment,
    stateHelpfulCount,
    stateHasUserUpvoted,
    toggleVote,
    disableVoteBtn,
    creatorReply,
    showDiscussionTag,
    isCollapsed,
    setCollapseValue,
    showStatusModerator,
    showSubmitReply,
    appendChild,
    replaceChild,
    setStatus,
    removePartnerStaffReply,
    isOwner,
    answerType,
    creatorReplyEditEnabled,
    setCreatorReplyEditEnabled,
  } = useCommentCard(
    review,
    CommentV2Type.Review,
    partner_user_ids,
    expandCard,
    showCollapseIcon,
  );

  const { asPath, push } = useRouter();

  const wrapperClassName =
    'flex flex-col items-start gap-4 self-stretch rounded border border-gravel p-4 shadow-pdp relative';

  const userInfo: IDiscussionUserInfoProps = useMemo(
    () => ({
      id: review.user.id,
      username: review.user.username,
      avatar: review.user.avatar,
      isVerified: true, // we always now have verified users
      isPlus: review.user.has_plus ?? false,
      userNamePadding: showCollapseIcon,
      memberSinceDate: new Date(review.user.date_joined),
      dealsBought: review.user.deals_purchased,
    }),
    [
      review.user.id,
      review.user.avatar,
      review.user.has_plus,
      review.user.username,
      showCollapseIcon,
      review.user.date_joined,
      review.user.deals_purchased,
    ],
  );

  const reviewInfo: IDiscussionReviewInfo = useMemo(
    () => ({
      rating: review.rating,
      userMemberDate: null,
      userDealsBought: null,
      publishDate: new Date(review?.created),
      title: review.title,
      content: review.comment,
      showText: !isCollapsed || !showCollapseIcon,
      isEdited: comment.edited,
    }),
    [
      review.rating,
      review?.created,
      review.title,
      review.comment,
      isCollapsed,
      showCollapseIcon,
      comment.edited,
    ],
  );

  return (
    <div className={wrapperClassName} data-testid="review-card-wrapper">
      {/* Collapse icon */}
      <div className="absolute right-4 flex gap-3">
        {/* Only owner can see options */}
        {isOwner && (
          <DiscussionOptions
            deleteUrl={`/api/v2/deals/${review.deal_id}/reviews/${review.id}/`}
            discussionType={CommentV2Type.Review}
            deletedCallback={() => onDelete(review.id)}
            onEditCallback={() =>
              push(`/products/${slug}/reviews/${review.id}/edit/`)
            }
          />
        )}
        {showCollapseIcon && (
          <button
            title="Collapse review"
            onClick={setCollapseValue}
            className="px-0.5"
            data-testid="review-collapse-icon"
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${!isCollapsed && 'rotate-180'}`}
            />
          </button>
        )}
      </div>

      {showDiscussionTag && (
        <DiscussionTag
          status={comment.status?.toLowerCase() as DiscussionStatus}
          isOwner={isOwner}
        />
      )}

      <DiscussionUserInfo {...userInfo} />

      <ReviewInfo {...reviewInfo} isCollapsed={isCollapsed} />

      {/* Collapsible content */}
      {(!isCollapsed || !showCollapseIcon) && (
        <>
          {!!creatorReply && !creatorReplyEditEnabled && (
            <CreatorReply
              {...creatorReply}
              onDelete={removePartnerStaffReply}
              onEdit={() => setCreatorReplyEditEnabled(true)}
            />
          )}

          <div className="flex w-full items-center justify-between">
            <HelpfulButton
              helpfulCount={stateHelpfulCount}
              hasUserUpvoted={stateHasUserUpvoted}
              isDisabled={disableVoteBtn}
              onToggleVote={toggleVote}
            />

            {!isIndexablePage(asPath.split('?')[0]) && (
              <Link
                className="text-slate hover:text-gray-500"
                href={review.display_path}
                target="_blank"
              >
                <span className="sr-only">See detail</span>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Link>
            )}
          </div>
        </>
      )}

      {showStatusModerator && (
        <DiscussionActions
          readOnly={!userStatus?.staff_moderator}
          canUndo={!!userStatus?.staff_moderator}
          canDecline={!!userStatus?.staff_moderator}
          status={comment.status?.toLowerCase() as DiscussionStatus}
          comment={comment}
          onChangeStatus={setStatus}
        />
      )}

      {/* Collapsible content */}
      {(!isCollapsed || !showCollapseIcon) && showSubmitReply && (
        <SubmitReply
          dealId={review.deal_id}
          mainComment={comment}
          currentComment={creatorReplyEditEnabled ? creatorReply : undefined}
          type={DEAL_COMMENT_TYPE.REVIEW}
          answerType={answerType}
          onSuccess={creatorReplyEditEnabled ? replaceChild : appendChild}
          onCancel={
            creatorReplyEditEnabled
              ? () => setCreatorReplyEditEnabled(false)
              : undefined
          }
        />
      )}
    </div>
  );
});

export { MemoizedReviewCard as ReviewCard };
