import {
  DiscussionFilterOption,
  DiscussionSortOption,
  TacoRatingFilterOption,
} from './types';

export const DISCUSSION_SECTION_ID = 'comments';

export enum DISCUSSION {
  QUESTIONS = 'questions',
  REVIEWS = 'reviews',
}

export enum DEAL_COMMENT_TYPE {
  REVIEW = 'dealreview',
  QUESTION = 'dealcomment',
}

export const filterOptions: DiscussionFilterOption[] = [
  { value: 'all', label: 'All' },
  { value: 'unresolved', label: 'Unresolved' },
  { value: 'unapproved', label: 'Pending' },
];

export const getFilterOptions = (
  type: DEAL_COMMENT_TYPE,
): DiscussionFilterOption[] => {
  const filterOptionsNewUI: DiscussionFilterOption[] = [
    { value: 'all', label: 'All' },
    { value: 'needs_reply', label: 'Needs reply' },
    { value: 'unapproved', label: 'Needs moderation' },
  ];

  if (type === DEAL_COMMENT_TYPE.QUESTION) {
    filterOptionsNewUI[1].label = 'Needs answer';
  }

  return filterOptionsNewUI;
};

export const getQuestionSortOptions = ({
  isPartner = false,
}: {
  isPartner: boolean;
}): DiscussionSortOption[] => {
  if (isPartner) {
    return [
      { value: 'newest', label: 'Newest' },
      { value: 'latest', label: 'Latest reply' },
      { value: 'helpful', label: 'Helpful' },
      { value: 'oldest', label: 'Oldest' },
    ];
  }

  return [
    { value: 'latest', label: 'Latest reply' },
    { value: 'helpful', label: 'Helpful' },
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
  ];
};

export const REVIEW_SORT_OPTIONS: DiscussionSortOption[] = [
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
  { value: 'rating:asc', label: 'Rating (Lowest)' },
  { value: 'rating:desc', label: 'Rating (Highest)' },
  { value: 'helpful', label: 'Helpful' },
];

export const TACO_RATING_FILTERS: TacoRatingFilterOption[] = [
  { value: 'all', label: 'All tacos' },
  { value: '5', label: '5 tacos' },
  { value: '4', label: '4 tacos' },
  { value: '3', label: '3 tacos' },
  { value: '2', label: '2 tacos' },
  { value: '1', label: '1 taco' },
];

export const ITEMS_PER_PAGE_ON_DEAL = 5;
export const ITEMS_PER_PAGE_ON_AGGREGATE = 20;
export const REPLY_MAX_LENGTH = 5000;
export const REVIEW_TITLE_MAX_LENGTH = 250;
export const QUESTION_TITLE_MAX_LENGTH = 115;
export const QUESTION_COMMENT_MAX_LENGTH = 850;
export const MAX_LEVEL_REPLY_ALLOWED = 2; // staring from 0
export const QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME =
  'question-thread-add-remove';
export const QUESTION_EVENT_TYPE_REPLY = 'reply';
export const QUESTION_EVENT_TYPE_DELETE = 'delete';
export const DISCUSSION_TOGGLE_TEXT_MAX_LENGTH = 400;
export const DISCUSSION_TACO_RATING_CHANGE_EVENT_NAME =
  'discussions:tacoRatingChange';

export const DISCUSSION_PAGE_MESSAGE_QUERY_PARAM = 'discussion_page_message';
export enum DISCUSSION_PAGE_MESSAGE_TYPE {
  EDIT = 'edit-success',
  CREATE = 'create-success',
}
export const REVIEW_PAGE_MESSAGE_CONFIG = {
  [DISCUSSION_PAGE_MESSAGE_TYPE.EDIT]:
    'Success! Your updates have been submitted for review.',
  [DISCUSSION_PAGE_MESSAGE_TYPE.CREATE]:
    'Success! Your review is pending moderation.',
};
export const QUESTION_PAGE_MESSAGE_CONFIG = {
  [DISCUSSION_PAGE_MESSAGE_TYPE.EDIT]:
    'Success! Your updates have been submitted for review.',
  [DISCUSSION_PAGE_MESSAGE_TYPE.CREATE]:
    'Success! Your question is pending moderation.',
};
