import { Form } from '@appsumo/dorado-react';
import {
  faExclamationCircle,
  faPaperPlane,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { QuestionV2 } from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { useSubmitReply } from '~/lib/util/hooks/submitReply';
import { YupQuestionSchema } from '~/types/question-review';

const TEXAREA_MIN_HEIGHT = 42;
const BORDER_WIDTH = 2;

function QuestionCommentInput({
  dealId,
  placeholder,
  onCancel,
  enableAutoFocus = false,
  comment,
  currentComment,
  onSuccess,
}: Readonly<{
  dealId: number;
  placeholder: string;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  enableAutoFocus?: boolean;
  comment?: { id: number };
  currentComment?: QuestionV2;
  onSuccess?: (comment: QuestionV2) => void;
}>) {
  const textAreaInput = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const dispatchEvent = useEventDispatch();

  const successCallback = useCallback(
    (comment: QuestionV2) => {
      if (onSuccess) {
        onSuccess(comment);
      }

      textAreaInput.current!.value = '';

      formRef?.current?.setValue(
        'comment',
        textAreaInput.current?.value.trim(),
      );
    },
    [onSuccess],
  );

  const { handleSubmit, isLoading, errors } = useSubmitReply({
    type: DEAL_COMMENT_TYPE.QUESTION,
    dealId,
    comment,
    currentComment,
    successCallback,
  });

  const [commentHasSingleLine, setCommentHasSingleLine] = useState(true);

  useEffect(() => {
    if (enableAutoFocus) {
      textAreaInput.current?.focus();
    }

    const textAreaElement = textAreaInput.current as HTMLTextAreaElement;
    const cssLineHeight = parseInt(
      getComputedStyle(textAreaElement).lineHeight,
      10,
    );
    const singleLineHeight = TEXAREA_MIN_HEIGHT + cssLineHeight - BORDER_WIDTH;

    // make input grow dynamically based on content
    const increaseTextAreaHeight = () => {
      const { scrollHeight } = textAreaElement;

      // FF doesn't apply the same default height for textarea as Chrome
      // so we need to keep the height of the textarea to a minimum when we only have one line
      setCommentHasSingleLine(scrollHeight < singleLineHeight);

      if (scrollHeight < singleLineHeight) {
        textAreaElement.style.height = TEXAREA_MIN_HEIGHT + 'px';
        return;
      }

      textAreaElement.style.height = 'auto';

      requestAnimationFrame(() => {
        textAreaElement.style.height = `${textAreaElement.scrollHeight + 2}px`;
      });
    };

    const keypressEvent = (event: KeyboardEvent) => {
      // prevent Enter on empty input
      if (event.key === 'Enter' && textAreaElement.value.trim() === '') {
        event.preventDefault();
      }
    };

    textAreaElement.addEventListener('input', increaseTextAreaHeight);
    textAreaElement.addEventListener('keypress', keypressEvent);

    return () => {
      textAreaElement.removeEventListener('input', increaseTextAreaHeight);
      textAreaElement.removeEventListener('keypress', keypressEvent);
    };
  }, [enableAutoFocus]);

  return (
    <div
      data-testid="question-comment-input"
      className="space-y-2"
      onFocus={() => dispatchEvent('pdp:toggleBuyButton', true)}
      onBlur={() => dispatchEvent('pdp:toggleBuyButton', false)}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        defaultValues={{ comment: currentComment?.comment || '' }}
        resolver={yupResolver(YupQuestionSchema)}
      >
        {onCancel && (
          <button
            data-testid="edit-button"
            type="button"
            className="text-sm font-medium text-bolt hover:text-bolt-light-20"
            onClick={onCancel}
          >
            <FontAwesomeIcon icon={faTimes} className="mr-1" />
            Cancel
          </button>
        )}

        <div className="relative mt-1">
          <textarea
            ref={textAreaInput}
            name="comment"
            placeholder={placeholder}
            className="h-[42px] w-full resize-none rounded-lg border border-grace bg-iceberg px-4 py-2 pr-12 placeholder:text-grace disabled:bg-sundog"
            rows={1}
            maxLength={400}
            disabled={isLoading}
          >
            {currentComment?.comment}
          </textarea>
          <button
            className={clsx(
              'absolute bottom-2 right-4 inline-block w-4 py-2 text-center text-black-pearl hover:text-gray-700',
              commentHasSingleLine ? 'top-0' : '',
            )}
            type="submit"
            disabled={isLoading}
            onClick={() =>
              formRef?.current?.setValue(
                'comment',
                textAreaInput.current?.value.trim(),
              )
            }
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            <span className="sr-only">Submit</span>
          </button>
        </div>
      </Form>

      {Object.keys(errors).length > 0 && (
        <div
          data-testid="generic-error"
          className="flex gap-4 rounded-sm bg-brick p-4 text-sm text-white"
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
          <p>
            Whoops! Something went wrong on our end. Please try submitting
            again.
          </p>
        </div>
      )}
    </div>
  );
}

export default memo(QuestionCommentInput);
