import { createContext } from 'react';
import { Deal } from '~/types/deal';
import { UserDiscussionStatus, UserObjectProps } from '~/types/user';

export type PDPContextType = {
  deal: Deal;
  isPDPPage?: boolean;
  galleryRef?: React.RefObject<HTMLDivElement>;
  userStatus?: UserDiscussionStatus;
  showTimer?: boolean;
  scZoneVariant?: string;
  user?: UserObjectProps;
  showQuantityRemaining?: boolean;
  showViewProductDetails?: boolean;
  isInactiveIndefinitely?: boolean;
  showColorfulShare?: boolean;
};

export const PDPContext = createContext({
  deal: {} as Deal,
  isPDPPage: false,
  galleryRef: {} as React.RefObject<HTMLDivElement>,
  userStatus: {} as UserDiscussionStatus,
  showTimer: false,
  scZoneVariant: 'disabled',
  user: undefined,
  showQuantityRemaining: false,
  showViewProductDetails: true,
  isInactiveIndefinitely: false,
  showColorfulShare: false,
} as PDPContextType);
