import React, { ReactElement } from 'react';
import Image from 'next/image';
import { Heading } from '@appsumo/dorado-react';

import Check from '~/public/font-awesome/check-dollar.png';

interface DealTermsProps {
  features: string[];
}

export default React.memo(function CoreFeatures({
  features,
}: Readonly<DealTermsProps>): ReactElement | null {
  if (!features?.length) return null;

  return (
    <div className="mt-6 rounded border border-sundog p-4">
      <Heading.H3 className="mb-4">Core features</Heading.H3>
      <ul className="appsumo-style-links flex flex-col gap-y-4">
        {features.map((feature, index) => (
          <li
            key={`core-feature-${index}`}
            className="flex items-center gap-x-2 leading-6"
          >
            <Image src={Check} alt="circle-notch" width={20} height={20} />
            <span dangerouslySetInnerHTML={{ __html: feature }} />
          </li>
        ))}
      </ul>
    </div>
  );
});
